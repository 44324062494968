import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthenticationService } from '@derena/api/v2';

@Component({
    selector: 'app-coach-reset-password',
    templateUrl: './coach-reset-password.component.html',
    styleUrls: ['./coach-reset-password.component.scss']
})
export class CoachResetPasswordComponent {
    emailFormControl = new FormControl('', [
        Validators.required,
        Validators.email,
    ]);
    submitting: boolean;

    constructor(private authService: AuthenticationService, private router: Router) { }

    async resetCoachPassword() {
        this.authService.forgotPassword({ email: this.emailFormControl.value }).toPromise();
        Swal.fire('Erfolg', 'Ihnen werden weitere Anweisungen an die eingegebene E-Mail gesendet, wenn das Konto existiert.', 'success').then(() => {
            this.router.navigate(['/login']);
        });
    }
}
