import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService} from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService, NewsArticle, NewsService, SystemService } from '@derena/api/v2';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {
    pushRightClass: string;
    title: string = 'DE-RENA CL';

    newsSub: Subscription;
    unreadNews: NewsArticle[] = [];
    toolTip: string;

    constructor(
            public router: Router,
            private translate: TranslateService,
            private authService: AuthenticationService,
            private newsService: NewsService,
            private systemService: SystemService
        ) {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd && window.innerWidth <= 2500 && this.isToggled()) {
                this.toggleSidebar();
            }
        });
    }

    ngOnInit() {
        this.pushRightClass = 'push-right';
        this.initNews();
        this.systemService.settings().subscribe((settings) => {
            this.title = settings.instance_name.split('"').join('');
        });
    }

    ngOnDestroy(): void {
        this.newsSub.unsubscribe();
    }

    async initNews() {
        this.newsSub = this.newsService.findAll().subscribe(
            (news) => {
                this.unreadNews = news.filter(x => !x.read).reverse();
                this.toolTip = this.unreadNews.length ? `Sie haben ${this.unreadNews.length} ungelesene News` : 'Keine neue News vorhanden';
            },
            (err) => {
                console.log(err);
            }
        );
    }

    goToNews(newsId: number) {
        this.router.navigate(['/news-dashboard'], {queryParams: {id: newsId}});
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    onLoggedOut() {
        this.authService.logout().subscribe(() => {
            localStorage.clear();
            this.router.navigate(['/login']);
        });
    }

    changeLang(language: string) {
        this.translate.use(language);
    }

    goToSettings(){
        this.router.navigate(['/settings']);
    }
}
