import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
import { ExternalComment } from '../models';

@Injectable({ providedIn: 'root' })
export class ExternalCommentsService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  findAll(query: {where?: any; limit?: number; skip?: number; order?: string}): Observable<ExternalComment[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    if (query) {
      for (const key in query) {
        query[key] = JSON.stringify(query[key]);
      }
    }
    return this.http.get<ExternalComment[]>(`${this.baseUrl}/v2/externalComments`, { headers, params: <any>query });
  }

  findById(id: string, ): Observable<ExternalComment> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<ExternalComment>(`${this.baseUrl}/v2/externalComments/${id}`, { headers,  });
  }

}
