import { Injectable } from '@angular/core';
import { SystemService as SystemApi } from '@derena/api/v2';
import { BehaviorSubject } from 'rxjs';

export type SystemSettings = {
    study_number_mode: 'alpha-numeric' | 'numeric' | string;
    next_patient_index_ref: number;
    next_patient_study_number: string;
    enable_creating_new_patients: boolean;
    show_patients_in_preparation: boolean;
    show_export_section: boolean;
};

@Injectable({ providedIn: 'root' })
export class SystemService {
    private settings = new BehaviorSubject<SystemSettings>(null);

    constructor(private systemApi: SystemApi) { }

    async getAsync(): Promise<SystemSettings> {
        return this.systemApi.settings().toPromise();
    }

    fetch() {
        this.systemApi.settings().subscribe(x => {
            this.settings.next(x);
        });
    }

    latest(): BehaviorSubject<SystemSettings> {
        return this.settings;
    }
}
