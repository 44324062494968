import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
import { Credentials, AccessToken, Coach, UpdatePassword, SetPassword, ForgotPasswordRequest } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  login(body: Credentials, ): Observable<AccessToken> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<AccessToken>(`${this.baseUrl}/v2/auth/login`, body, { headers,  });
  }

  logout(): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/auth/logout`, {},{ headers,  });
  }

  me(): Observable<Coach> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<Coach>(`${this.baseUrl}/v2/auth/me`, { headers,  });
  }

  updatePassword(body: UpdatePassword, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.patch<any>(`${this.baseUrl}/v2/auth/me/update-password`, body, { headers,  });
  }

  setPassword(body: SetPassword, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/auth/me/set-password`, body, { headers,  });
  }

  forgotPassword(body: ForgotPasswordRequest, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/auth/forgot-password`, body, { headers,  });
  }

}
