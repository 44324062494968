import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
import { CostRate, EntityCreatedResponse, UpdateCostRate } from '../models';

@Injectable({ providedIn: 'root' })
export class CostRatesService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  create(body: CostRate, ): Observable<EntityCreatedResponse> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<EntityCreatedResponse>(`${this.baseUrl}/v2/cost-rates`, body, { headers,  });
  }

  findAll(query: {where?: any; limit?: number; skip?: number; order?: string}): Observable<CostRate[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    if (query) {
      for (const key in query) {
        query[key] = JSON.stringify(query[key]);
      }
    }
    return this.http.get<CostRate[]>(`${this.baseUrl}/v2/cost-rates`, { headers, params: <any>query });
  }

  findById(id: string, ): Observable<CostRate> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<CostRate>(`${this.baseUrl}/v2/cost-rates/${id}`, { headers,  });
  }

  replaceById(id: string, body: UpdateCostRate, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<any>(`${this.baseUrl}/v2/cost-rates/${id}`, body, { headers,  });
  }

  updateById(id: string, body: CostRate, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.patch<any>(`${this.baseUrl}/v2/cost-rates/${id}`, body, { headers,  });
  }

  deleteById(id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/cost-rates/${id}`, { headers,  });
  }

}
