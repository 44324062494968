import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SessionService } from './session.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(translate: TranslateService, private router: Router, sessionService: SessionService) {
        translate.setDefaultLang('de');
        sessionService.fetchMe();
    }

    ngOnInit() {
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

        if(isIEOrEdge){
            this.router.navigate(['/internet-explorer-msg']);
        }
    }
}
