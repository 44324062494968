import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
import { Coach, EntityCreatedResponse, UpdateCoach, Patient, CreatePatientToken, PatientToken } from '../models';

@Injectable({ providedIn: 'root' })
export class CoachesService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  create(body: Coach, ): Observable<EntityCreatedResponse> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<EntityCreatedResponse>(`${this.baseUrl}/v2/coaches`, body, { headers,  });
  }

  findAll(query: {where?: any; limit?: number; skip?: number; order?: string}): Observable<Coach[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    if (query) {
      for (const key in query) {
        query[key] = JSON.stringify(query[key]);
      }
    }
    return this.http.get<Coach[]>(`${this.baseUrl}/v2/coaches`, { headers, params: <any>query });
  }

  findById(id: string, ): Observable<Coach> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<Coach>(`${this.baseUrl}/v2/coaches/${id}`, { headers,  });
  }

  replaceById(id: string, body: UpdateCoach, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<any>(`${this.baseUrl}/v2/coaches/${id}`, body, { headers,  });
  }

  updateById(id: string, body: UpdateCoach, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.patch<any>(`${this.baseUrl}/v2/coaches/${id}`, body, { headers,  });
  }

  deleteById(id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/coaches/${id}`, { headers,  });
  }

  connect(): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/coaches/connect`, {},{ headers,  });
  }

  isCoachLocked(): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<any>(`${this.baseUrl}/v2/coaches/isCoachLocked`, { headers,  });
  }

  createPatientForMedian(): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/coaches/createPatientForMedian`, {},{ headers,  });
  }

  sendVerificationEmail(coachId: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/coaches/${coachId}/send-verification-email`, {},{ headers,  });
  }

  createTrialAccess(): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<any>(`${this.baseUrl}/v2/coaches/create-trial-access`, { headers,  });
  }

  getPatientsByCoachId(id: string, ): Observable<Patient[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<Patient[]>(`${this.baseUrl}/v2/coaches/${id}/patients`, { headers,  });
  }

  createPatientToken(id: string, body: CreatePatientToken, ): Observable<PatientToken> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<PatientToken>(`${this.baseUrl}/v2/coaches/${id}/create-patient-token`, body, { headers,  });
  }

  linkPatient(id: string, patientId: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/coaches/${id}/link-patient/${patientId}`, {},{ headers,  });
  }

  verifyEmail(id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<any>(`${this.baseUrl}/v2/coaches/${id}/verify-email`, { headers,  });
  }

  resetPassword(id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/coaches/${id}/reset-password`, {},{ headers,  });
  }

}
