import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { CoachesService, ReplicationEndpoint, ReplicationEndpointService } from '@derena/api/v2';

@Injectable({ providedIn: 'root' })
export class EndpointInterceptor implements HttpInterceptor {
    private endpoint: ReplicationEndpoint;
    private session: string;
    private changeStream = new Subject<EndpointInterceptor>();

    constructor(private endpointService: ReplicationEndpointService, private coachService: CoachesService) { }

    get name(): string {
        if (!this.endpoint)
            return 'DE-RENA CL';
        return `DE-RENA CL | ${this.endpoint.name}`;
    }

    get url(): string {
        if (!this.endpoint)
            return null;
        return `${this.endpoint.protocol}://${this.endpoint.host}:${this.endpoint.port}`
    }

    get valid(): boolean {
        return !!this.session;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.session)
            return next.handle(req);

        req.headers.append('Cookie', `AuthSession=${this.session}`);
        req.headers.append('X-Replication-Endpoint', this.session);

        return next.handle(req);
    }

    async init(endpointId: string) {
        this.endpoint = await this.endpointService.findById(endpointId).toPromise();
        this.changeStream.next(this);
    }

    changes(): Observable<EndpointInterceptor> {
        return this.changeStream.asObservable();
    }
}
