import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
import { PatientStatisticEntry, PatientDetailStatisticEntry, DailyPatientsInAftercare, PhoneCoachingsStatisticsEntry } from '../models';

@Injectable({ providedIn: 'root' })
export class StatisticService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  getPatientStatistics(query: {startDate?: string; endDate?: string; interval?: string}): Observable<PatientStatisticEntry[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<PatientStatisticEntry[]>(`${this.baseUrl}/v2/statistics/patient-statistics`, { headers, params: <any>query });
  }

  getPatientDetailStatistics(query: {startDate?: string; endDate?: string; interval?: string}): Observable<PatientDetailStatisticEntry[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<PatientDetailStatisticEntry[]>(`${this.baseUrl}/v2/statistics/patient-detail-statistics`, { headers, params: <any>query });
  }

  getDailyPatientsInAftercare(query: {startDate?: string; endDate?: string}): Observable<DailyPatientsInAftercare[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<DailyPatientsInAftercare[]>(`${this.baseUrl}/v2/statistics/daily-patients-in-aftercare`, { headers, params: <any>query });
  }

  getPhoneCoachings(query: {startDate?: string; endDate?: string; interval?: string}): Observable<PhoneCoachingsStatisticsEntry[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<PhoneCoachingsStatisticsEntry[]>(`${this.baseUrl}/v2/statistics/phone-coachings`, { headers, params: <any>query });
  }

}
