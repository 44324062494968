<div class="coach-reset-password-page">
    <div class="content">
        <form fxFlex class="email-form">
            <div class="text-center">
                <h2 class="form-message">Ihre E-Mail Adresse</h2>
            </div>
            <div fxFlex fxlayout="row" fxlayout.lt-md="column">
                <div fxFlexFill>
                    <mat-form-field class="w-100">
                        <input matInput placeholder="Email" [formControl]="emailFormControl">
                        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                            Bitte eine gültige E-Mail Adresse eingeben
                        </mat-error>
                        <mat-error *ngIf="emailFormControl.hasError('required')">
                            E-Mail ist ein <strong>pflicht</strong> Feld
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex  fxLayout="row" fxLayout.lt-md="column">
                <div fxFlexFill style="margin-top: 20px;">
                    <button mat-raised-button [disabled]="submitting" color="primary" class="w-100" (click)="resetCoachPassword()">Passwort zurücksetzen</button>
                </div>
            </div>
        </form>
    </div>
  </div>
