import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
@Injectable({ providedIn: 'root' })
export class AppSettingsService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  getCachedSettings(): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<any>(`${this.baseUrl}/v2/app-settings`, { headers,  });
  }

  getVersion(): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<any>(`${this.baseUrl}/v2/app-settings/version`, { headers,  });
  }

  getAsset(id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<any>(`${this.baseUrl}/v2/app-settings/asset/${id}`, { headers,  });
  }

}
