import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
import { SystemSettings, SystemSetting } from '../models';

@Injectable({ providedIn: 'root' })
export class SystemService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  settings(): Observable<SystemSettings> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SystemSettings>(`${this.baseUrl}/v2/system/settings`, { headers,  });
  }

  trainingMaterials(): Observable<SystemSetting> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SystemSetting>(`${this.baseUrl}/v2/system/training-materials`, { headers,  });
  }

}
