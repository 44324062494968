import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
import { ReplicationEndpoint, EntityCreatedResponse, UpdateReplicationEndpoint } from '../models';

@Injectable({ providedIn: 'root' })
export class ReplicationEndpointService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  create(body: ReplicationEndpoint, ): Observable<EntityCreatedResponse> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<EntityCreatedResponse>(`${this.baseUrl}/v2/replicationEndpoint`, body, { headers,  });
  }

  findAll(query: {where?: any; limit?: number; skip?: number; order?: string}): Observable<ReplicationEndpoint[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    if (query) {
      for (const key in query) {
        query[key] = JSON.stringify(query[key]);
      }
    }
    return this.http.get<ReplicationEndpoint[]>(`${this.baseUrl}/v2/replicationEndpoint`, { headers, params: <any>query });
  }

  findById(id: string, ): Observable<ReplicationEndpoint> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<ReplicationEndpoint>(`${this.baseUrl}/v2/replicationEndpoint/${id}`, { headers,  });
  }

  replaceById(id: string, body: UpdateReplicationEndpoint, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<any>(`${this.baseUrl}/v2/replicationEndpoint/${id}`, body, { headers,  });
  }

  updateById(id: string, body: ReplicationEndpoint, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.patch<any>(`${this.baseUrl}/v2/replicationEndpoint/${id}`, body, { headers,  });
  }

  deleteById(id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/replicationEndpoint/${id}`, { headers,  });
  }

}
