import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
import { NewsArticle } from '../models';

@Injectable({ providedIn: 'root' })
export class NewsService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  findAll(): Observable<NewsArticle[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<NewsArticle[]>(`${this.baseUrl}/v2/news`, { headers,  });
  }

  markRead(coachId: string, id: number, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/news/${id}/read/${coachId}`, {},{ headers,  });
  }

  unmarkRead(coachId: string, id: number, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/news/${id}/unread/${coachId}`, {},{ headers,  });
  }

}
