import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '../core/credentials.service';
import { Patient, EntityCreatedResponse, UpdatePatient, Coach, PatientToken, PatientPuk, UpdateAppointments, EvaluationForm, Device, SyncDbDayEvaluation, SyncDbDayStats, SyncDbEvent, SyncDbEventTemplate, SyncDbForm, SyncDbInternal, SyncDbLifeSection, SyncDbSettings, SyncDbSurvey, SyncDbSurveyResult, SyncDbTimeframe, SyncDbTimeframeEvaluation } from '../models';

@Injectable({ providedIn: 'root' })
export class PatientsService {

  constructor(private http: HttpClient, private credentialsService: CredentialsService, @Inject('BASE_URL') private baseUrl: string) {}

  create(body: Patient, ): Observable<EntityCreatedResponse> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<EntityCreatedResponse>(`${this.baseUrl}/v2/patients`, body, { headers,  });
  }

  findAll(query: {where?: any; limit?: number; skip?: number; order?: string}): Observable<Patient[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    if (query) {
      for (const key in query) {
        query[key] = JSON.stringify(query[key]);
      }
    }
    return this.http.get<Patient[]>(`${this.baseUrl}/v2/patients`, { headers, params: <any>query });
  }

  findById(id: string, ): Observable<Patient> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<Patient>(`${this.baseUrl}/v2/patients/${id}`, { headers,  });
  }

  replaceById(id: string, body: UpdatePatient, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<any>(`${this.baseUrl}/v2/patients/${id}`, body, { headers,  });
  }

  updateById(id: string, body: UpdatePatient, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.patch<any>(`${this.baseUrl}/v2/patients/${id}`, body, { headers,  });
  }

  deleteById(id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${id}`, { headers,  });
  }

  getPrimaryCoach(id: string, ): Observable<Coach> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<Coach>(`${this.baseUrl}/v2/patients/${id}/primary-coach`, { headers,  });
  }

  getOrCreateUnusedPatientToken(id: string, ): Observable<PatientToken> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<PatientToken>(`${this.baseUrl}/v2/patients/${id}/token/get-or-create-unused`, { headers,  });
  }

  getAllPatientPuk(id: string, ): Observable<PatientPuk[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<PatientPuk[]>(`${this.baseUrl}/v2/patients/${id}/puks`, { headers,  });
  }

  createPatientPuk(id: string, body: PatientPuk, ): Observable<EntityCreatedResponse> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<EntityCreatedResponse>(`${this.baseUrl}/v2/patients/${id}/puks`, body, { headers,  });
  }

  getAllCoaches(id: string, ): Observable<Coach[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<Coach[]>(`${this.baseUrl}/v2/patients/${id}/coaches`, { headers,  });
  }

  linkCoach(id: string, coachId: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/patients/${id}/coaches/${coachId}`, {},{ headers,  });
  }

  unlinkCoach(id: string, coachId: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${id}/coaches/${coachId}`, { headers,  });
  }

  linkDevice(id: string, deviceId: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.post<any>(`${this.baseUrl}/v2/patients/${id}/devices/${deviceId}`, {},{ headers,  });
  }

  unlinkDevice(id: string, deviceId: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${id}/devices/${deviceId}`, { headers,  });
  }

  updateAppointments(id: string, body: UpdateAppointments, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<any>(`${this.baseUrl}/v2/patients/${id}/appointments`, body, { headers,  });
  }

  getEvaluationForm(id: string, ): Observable<EvaluationForm> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<EvaluationForm>(`${this.baseUrl}/v2/patients/${id}/evaluation-form`, { headers,  });
  }

  updateEvaluationForm(id: string, body: EvaluationForm, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<any>(`${this.baseUrl}/v2/patients/${id}/evaluation-form`, body, { headers,  });
  }

  getAllDevices(id: string, ): Observable<Device[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<Device[]>(`${this.baseUrl}/v2/patients/${id}/devices`, { headers,  });
  }

  getAllDayEvaluations(patientId: string, ): Observable<SyncDbDayEvaluation[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbDayEvaluation[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/day-evaluation`, { headers,  });
  }

  upsertDayEvaluation(patientId: string, body: SyncDbDayEvaluation, ): Observable<SyncDbDayEvaluation> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbDayEvaluation>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/day-evaluation`, body, { headers,  });
  }

  getDayEvaluation(patientId: string, id: string, ): Observable<SyncDbDayEvaluation> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbDayEvaluation>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/day-evaluation/${id}`, { headers,  });
  }

  deleteDayEvaluation(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/day-evaluation/${id}`, { headers,  });
  }

  getAllDayStats(patientId: string, ): Observable<SyncDbDayStats[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbDayStats[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/day-stats`, { headers,  });
  }

  upsertDayStats(patientId: string, body: SyncDbDayStats, ): Observable<SyncDbDayStats> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbDayStats>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/day-stats`, body, { headers,  });
  }

  getDayStats(patientId: string, id: string, ): Observable<SyncDbDayStats> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbDayStats>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/day-stats/${id}`, { headers,  });
  }

  deleteDayStats(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/day-stats/${id}`, { headers,  });
  }

  getAllEvents(patientId: string, ): Observable<SyncDbEvent[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbEvent[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/event`, { headers,  });
  }

  upsertEvent(patientId: string, body: SyncDbEvent, ): Observable<SyncDbEvent> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbEvent>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/event`, body, { headers,  });
  }

  getEvent(patientId: string, id: string, ): Observable<SyncDbEvent> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbEvent>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/event/${id}`, { headers,  });
  }

  deleteEvent(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/event/${id}`, { headers,  });
  }

  getAllEventTemplates(patientId: string, ): Observable<SyncDbEventTemplate[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbEventTemplate[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/event-template`, { headers,  });
  }

  upsertEventTemplate(patientId: string, body: SyncDbEventTemplate, ): Observable<SyncDbEventTemplate> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbEventTemplate>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/event-template`, body, { headers,  });
  }

  getEventTemplate(patientId: string, id: string, ): Observable<SyncDbEventTemplate> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbEventTemplate>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/event-template/${id}`, { headers,  });
  }

  deleteEventTemplate(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/event-template/${id}`, { headers,  });
  }

  getAllForms(patientId: string, ): Observable<SyncDbForm[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbForm[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/form`, { headers,  });
  }

  upsertForm(patientId: string, body: SyncDbForm, ): Observable<SyncDbForm> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbForm>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/form`, body, { headers,  });
  }

  getForm(patientId: string, id: string, ): Observable<SyncDbForm> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbForm>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/form/${id}`, { headers,  });
  }

  deleteForm(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/form/${id}`, { headers,  });
  }

  getAllInternals(patientId: string, ): Observable<SyncDbInternal[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbInternal[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/internal`, { headers,  });
  }

  upsertInternal(patientId: string, body: SyncDbInternal, ): Observable<SyncDbInternal> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbInternal>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/internal`, body, { headers,  });
  }

  getInternal(patientId: string, id: string, ): Observable<SyncDbInternal> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbInternal>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/internal/${id}`, { headers,  });
  }

  deleteInternal(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/internal/${id}`, { headers,  });
  }

  getAllLifeSections(patientId: string, ): Observable<SyncDbLifeSection[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbLifeSection[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/life-section`, { headers,  });
  }

  upsertLifeSection(patientId: string, body: SyncDbLifeSection, ): Observable<SyncDbLifeSection> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbLifeSection>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/life-section`, body, { headers,  });
  }

  getLifeSection(patientId: string, id: string, ): Observable<SyncDbLifeSection> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbLifeSection>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/life-section/${id}`, { headers,  });
  }

  deleteLifeSection(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/life-section/${id}`, { headers,  });
  }

  getAllSettings(patientId: string, ): Observable<SyncDbSettings[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbSettings[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/settings`, { headers,  });
  }

  upsertSetting(patientId: string, body: SyncDbSettings, ): Observable<SyncDbSettings> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbSettings>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/settings`, body, { headers,  });
  }

  getSetting(patientId: string, id: string, ): Observable<SyncDbSettings> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbSettings>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/settings/${id}`, { headers,  });
  }

  deleteSetting(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/settings/${id}`, { headers,  });
  }

  getAllSurveys(patientId: string, ): Observable<SyncDbSurvey[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbSurvey[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/survey`, { headers,  });
  }

  upsertSurvey(patientId: string, body: SyncDbSurvey, ): Observable<SyncDbSurvey> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbSurvey>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/survey`, body, { headers,  });
  }

  getSurvey(patientId: string, id: string, ): Observable<SyncDbSurvey> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbSurvey>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/survey/${id}`, { headers,  });
  }

  deleteSurvey(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/survey/${id}`, { headers,  });
  }

  getAllSurveyResults(patientId: string, ): Observable<SyncDbSurveyResult[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbSurveyResult[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/survey-result`, { headers,  });
  }

  upsertSurveyResult(patientId: string, body: SyncDbSurveyResult, ): Observable<SyncDbSurveyResult> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbSurveyResult>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/survey-result`, body, { headers,  });
  }

  getSurveyResult(patientId: string, id: string, ): Observable<SyncDbSurveyResult> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbSurveyResult>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/survey-result/${id}`, { headers,  });
  }

  deleteSurveyResult(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/survey-result/${id}`, { headers,  });
  }

  getAllTimeframes(patientId: string, ): Observable<SyncDbTimeframe[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbTimeframe[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/timeframe`, { headers,  });
  }

  upsertTimeframe(patientId: string, body: SyncDbTimeframe, ): Observable<SyncDbTimeframe> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbTimeframe>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/timeframe`, body, { headers,  });
  }

  getTimeframe(patientId: string, id: string, ): Observable<SyncDbTimeframe> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbTimeframe>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/timeframe/${id}`, { headers,  });
  }

  deleteTimeframe(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/timeframe/${id}`, { headers,  });
  }

  getAllTimeframeEvaluations(patientId: string, ): Observable<SyncDbTimeframeEvaluation[]> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbTimeframeEvaluation[]>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/timeframe-evaluation`, { headers,  });
  }

  upsertTimeframeEvaluation(patientId: string, body: SyncDbTimeframeEvaluation, ): Observable<SyncDbTimeframeEvaluation> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.put<SyncDbTimeframeEvaluation>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/timeframe-evaluation`, body, { headers,  });
  }

  getTimeframeEvaluation(patientId: string, id: string, ): Observable<SyncDbTimeframeEvaluation> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.get<SyncDbTimeframeEvaluation>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/timeframe-evaluation/${id}`, { headers,  });
  }

  deleteTimeframeEvaluation(patientId: string, id: string, ): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.credentialsService.getAccessToken() });
    return this.http.delete<any>(`${this.baseUrl}/v2/patients/${patientId}/sync-db/timeframe-evaluation/${id}`, { headers,  });
  }

}
