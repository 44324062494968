import { Injectable } from '@angular/core';
import { AuthenticationService } from '../apiv2/services';
import { Subject } from 'rxjs';
import { Coach } from '../apiv2/models';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public readonly me$ = new Subject<Coach>();

    private _me: Coach;

    constructor(
        private readonly authService: AuthenticationService
    ) {}

    get me(): Coach {
        return this._me;
    }

    fetchMe(): Promise<Coach> {
        return new Promise<Coach>((resolve, reject) => {
            this.authService.me().subscribe((me) => {
                this._me = me;
                this.me$.next(me);
                resolve(me);
            });
        });
    }
}
