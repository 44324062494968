import {Injectable} from '@angular/core';
import {EndpointInterceptor} from '../../login/endpoint.interceptor';
import Swal from 'sweetalert2';
import { AuthenticationService, Coach, CredentialsService } from '@derena/api/v2';

@Injectable({ providedIn: 'root' })
export class LoginService {
    constructor(
        private authService: AuthenticationService,
        private credentialService: CredentialsService,
        private endpoint: EndpointInterceptor,
    ) { }

    async tryLogin(): Promise<boolean> {
        const accessToken = this.credentialService.getAccessToken();
        if (!accessToken) {
            return false;
        }

        try {
            const user = await this.authService.me().toPromise();
            if (user.isUserLocked) {
                await this.authService.logout().toPromise();
                this.credentialService.clearAccessToken();
                return false;
            }
        } catch (e) {
            console.log('token is expired');
            this.credentialService.clearAccessToken();
            return false;
        }

        return true;
    }

    async login(username, password): Promise<Coach> {
        try {
            const result = await this.authService.login({
                email: username,
                password: password
            }).toPromise();

            if (result.id) {
                this.credentialService.setAccessToken(result.id);
                return result.user;
            }
        } catch (e) {
            if (e.statusCode === 403) {
              Swal.fire('Login Fehler', 'Dieses Konto wurde gesperrt!', 'error');
            }
        }
        return null;
    }
}
