import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { CredentialsService } from './core/credentials.service';

import { AppSettingsService } from './services/app-settings.service';
import { AuthenticationService } from './services/authentication.service';
import { CoachesService } from './services/coaches.service';
import { CommentsService } from './services/comments.service';
import { CostRatesService } from './services/cost-rates.service';
import { DevicesService } from './services/devices.service';
import { DiagnosisService } from './services/diagnosis.service';
import { ExternalCommentsService } from './services/external-comments.service';
import { NewsService } from './services/news.service';
import { PatientTokensService } from './services/patient-tokens.service';
import { PatientsService } from './services/patients.service';
import { ReplicationEndpointService } from './services/replication-endpoint.service';
import { StatisticService } from './services/statistic.service';
import { SurveysService } from './services/surveys.service';
import { SyncedPatientsService } from './services/synced-patients.service';
import { SystemService } from './services/system.service';

export interface ApiModuleConfig {
  baseUrl: string;
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    CredentialsService,
    AppSettingsService, AuthenticationService, CoachesService, CommentsService, CostRatesService, DevicesService, DiagnosisService, ExternalCommentsService, NewsService, PatientTokensService, PatientsService, ReplicationEndpointService, StatisticService, SurveysService, SyncedPatientsService, SystemService
  ]
})
export class ApiModule {
  static forRoot(config: ApiModuleConfig): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        { provide: 'BASE_URL', useValue: config.baseUrl },
        CredentialsService,
        AppSettingsService, AuthenticationService, CoachesService, CommentsService, CostRatesService, DevicesService, DiagnosisService, ExternalCommentsService, NewsService, PatientTokensService, PatientsService, ReplicationEndpointService, StatisticService, SurveysService, SyncedPatientsService, SystemService
      ]
    };
  }
}