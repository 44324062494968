<mat-toolbar color="primary" class="fix-nav">
    <button type="button" mat-icon-button class="visible-md" (click)="toggleSidebar()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <div class="hidden-sm" >Menü</div>
    <div class="nav-brand">{{title}}</div>
    <span class="nav-spacer"></span>
    <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="news">
        <mat-icon *ngIf="unreadNews?.length > 0" [matTooltip]="toolTip" matBadge="{{unreadNews?.length}}" matBadgeColor="warn">newspaper</mat-icon>
        <mat-icon *ngIf="!unreadNews || (unreadNews?.length == 0)" [routerLink]="['/news-dashboard']" [matTooltip]="toolTip">newspaper</mat-icon>
    </button>
    <mat-menu #news="matMenu">
        <button mat-menu-item *ngFor="let item of unreadNews" (click)="goToNews(item.id)">
            <span>{{item?.title}}</span>
        </button>
    </mat-menu>
    <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="language">
        <mat-icon>language</mat-icon>
    </button>
    <mat-menu #language="matMenu">
        <button mat-menu-item (click)="changeLang('de')">
            <span>{{ 'German' | translate }}</span>
        </button>
    </mat-menu>
    <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="profile">
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #profile="matMenu">
        <button mat-menu-item>
            <mat-icon>inbox</mat-icon>
            <span>{{ 'Inbox' | translate }}</span>
        </button>
        <button mat-menu-item (click)="goToSettings()">
            <mat-icon>settings</mat-icon>
            <span>{{ 'Settings' | translate }}</span>
        </button>
    </mat-menu>
    <button mat-icon-button (click)="onLoggedOut()">
        <mat-icon>exit_to_app</mat-icon>
    </button>
</mat-toolbar>
