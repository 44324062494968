<div id="sidebar">
    <mat-nav-list>
        <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/dashboard']">
            <mat-icon class="sidenav-icon">dashboard</mat-icon> {{ 'Dashboard' | translate }}
        </a>
        <a mat-list-item *ngIf="showCreateAccess" [routerLinkActive]="'active'" [routerLink]="['/create-access']">
            <mat-icon class="sidenav-icon">add</mat-icon> {{ 'New User' | translate }}
        </a>
        <a mat-list-item *ngIf="showAdminItems" [routerLinkActive]="'active'" [routerLink]="['/admin']">
            <mat-icon class="sidenav-icon">account_box</mat-icon> {{ 'Coaches verwalten' | translate }}
        </a>
        <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/information']" style="white-space: pre-line;">
            <mat-icon class="sidenav-icon">info</mat-icon> {{ 'Hilfe & Unterlagen & Infos' | translate }}
        </a>
        <a mat-list-item *ngIf="showStatsItems || showAdminItems" [routerLinkActive]="'active'" [routerLink]="['/stats']" style="white-space: pre-line;">
            <mat-icon class="sidenav-icon">analytics</mat-icon> {{ 'Controlling' | translate }}
        </a>
        <a mat-list-item *ngIf="showAdminItems" [routerLinkActive]="'active'" [routerLink]="['/export']" style="white-space: pre-line;">
            <mat-icon class="sidenav-icon">save_alt</mat-icon> {{ 'Export' | translate }}
        </a>
        <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/news-dashboard']">
            <mat-icon class="sidenav-icon">newspaper</mat-icon> {{ 'News' | translate }}
        </a>
    </mat-nav-list>
</div>
