<div class="reset-password-page">
  <div class="content">
      <form fxFlex class="password-form">
          <div class="text-center">
              <h2 class="app-name">Neues Passwort</h2>
          </div>
          <div fxFlex fxlayout="row" fxlayout.lt-md="column">
              <div fxFlexFill>
                  <mat-form-field class="w-100">
                      <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder="Ihr neues Passwort" [formControl]="passwordFormControl">
                      <mat-icon matListIcon matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                      <mat-error *ngIf="passwordFormControl.hasError('required')">
                        <strong>Ihr neues Passwort</strong> darf nicht leer sein
                      </mat-error>
                      <mat-error *ngIf="passwordFormControl.hasError('minlength')" style="text-align: center">
                        <strong>Ihr neues Passwort</strong> muss mindestens 8 Zeichen lang sein!
                      </mat-error>
                  </mat-form-field>
              </div>
          </div>
          <div fxFlex style="margin-top: 10px;" fxLayout="row" fxLayout.lt-md="column">
              <div fxFlexFill>
                  <mat-form-field class="w-100">
                      <input matInput [type]="hideConfirmedPassword ? 'password' : 'text'" placeholder="Wiederholen Sie Ihr neues Passwort" [formControl]="passwordConfirmationFormControl">
                      <mat-icon matListIcon matSuffix (click)="hideConfirmedPassword = !hideConfirmedPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmedPassword">{{hideConfirmedPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                      <mat-error *ngIf="passwordConfirmationFormControl.hasError('required')">
                        <strong>Die Bestätigung Ihr neue Passwort</strong> darf nicht leer sein
                      </mat-error>
                      <mat-error *ngIf="passwordConfirmationFormControl.hasError('minlength')" style="text-align: center">
                        <strong>Ihr neues Passwort</strong> muss mindestens 8 Zeichen lang sein!
                    </mat-error>
                  </mat-form-field>
              </div>
              <div *ngIf="submittingErrorMsg !== ''" style="text-align: center; color: red;">
                <strong>Fehler aufgetreten!</strong> {{ submittingErrorMsg }}
              </div>
          </div>
          <div fxFlex  fxLayout="row" fxLayout.lt-md="column">
              <div fxFlexFill style="margin-top: 20px;">
                  <button mat-raised-button [disabled]="submitting" color="primary" class="w-100" (click)="changePassword()">Passwort ändern</button>
              </div>
          </div>
      </form>
  </div>
</div>